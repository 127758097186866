<!--
 * @Description:用户端-首页-周边泊位-详情 aroundParkInfo
 * @Author: zhoucheng
 * @Github:无
 * @Date: 2021-04-01 12:48:17
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="停车场详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />

    <div class="top">
      <div class="top-left">
        <van-image :src="require('@/assets/aroundParkSpace/tingchechang.png')"></van-image>
      </div>
      <div class="top-right">
        <div class="parking">{{item.parkName}}</div>
        <div class="row2">
          <span class="row2-1">空位:<span class="prominent">{{item.emptySpace}}</span> 个</span>
          <span>距离:<span class="prominent">{{getDistance[0]}}</span>{{getDistance[1]}}</span>
        </div>
        <div class="row2">
          <span class="row2-1">联系人:{{parkInfo.contact}}</span>
          <span>电话:{{parkInfo.contactPhoneNumber}}</span>
        </div>
        <div class="row4">
          <van-image :src="require('@/assets/aroundParkSpace/ditusmall.png')"></van-image>
          <span>{{item.address}}</span>
        </div>
      </div>
    </div>
    <div class="rule-box">
      <div class="rule-title">收费规则（小车）</div>

      <div class="rule-item">1.白天({{Number(billRule[0].dayBeginTime) / 60 + ":00"}}-{{Number(billRule[0].dayEndTime) / 60 + ":00"}})</div>
      <div class="table">
        <div class="tr">
          <div class="th">计费金额</div>
          <div class="th">免费时长</div>
          <div class="th">重新计费时长</div>
          <div class="th">封顶金额</div>
        </div>
        <div class="tr">
          <div class="td">{{Number(Number(billRule[0].dayBillingPrice)/100).toFixed(2)}}元</div>
          <div class="td">{{billRule[0].dayFreeDuration}}分钟</div>
          <div class="td">{{billRule[0].dayMinBillingDuration}}分钟</div>
          <div class="td">{{Number(Number(billRule[0].dayCeilingAmount)/100).toFixed(2)}}元</div>
        </div>
      </div>
      <div class="rule-item">2.夜间({{Number(billRule[0].nightBeginTime) / 60 + ":00"}}-{{(Number(billRule[0].nightEndTime) / 60?(Number(billRule[0].nightEndTime) / 60)-24:Number(billRule[0].nightEndTime) / 60 )+ ":00"}})</div>
      <div class="table">
        <div class="tr">
          <div class="th">计费金额</div>
          <div class="th">免费时长</div>
          <div class="th">重新计费时长</div>
          <div class="th">封顶金额</div>
        </div>
        <div class="tr">
          <div class="td">{{Number(Number(billRule[0].nightBillingPrice)/100).toFixed(2)}}元</div>
          <div class="td">{{billRule[0].nightFreeDuration}}分钟</div>
          <div class="td">{{billRule[0].nightMinBillingDuration}}分钟</div>
          <div class="td">{{Number(Number(billRule[0].nightCeilingAmount)/100).toFixed(2)}}元</div>
        </div>
      </div>
      <div class="rule-title">收费规则（大车）</div>
      <div class="rule-item">1.白天({{Number(billRule[1].dayBeginTime) / 60 + ":00"}}-{{Number(billRule[1].dayEndTime) / 60 + ":00"}})</div>
      <div class="table">
        <div class="tr">
          <div class="th">计费金额</div>
          <div class="th">免费时长</div>
          <div class="th">重新计费时长</div>
          <div class="th">封顶金额</div>
        </div>
        <div class="tr">
          <div class="td">{{Number(Number(billRule[1].dayBillingPrice)/100).toFixed(2)}}元</div>
          <div class="td">{{billRule[1].dayFreeDuration}}分钟</div>
          <div class="td">{{billRule[1].dayMinBillingDuration}}分钟</div>
          <div class="td">{{Number(Number(billRule[1].dayCeilingAmount)/100).toFixed(2)}}元</div>
        </div>
      </div>
      <div class="rule-item">2.夜间({{Number(billRule[1].nightBeginTime) / 60 + ":00"}}-{{(Number(billRule[1].nightEndTime) / 60?(Number(billRule[1].nightEndTime) / 60)-24:Number(billRule[1].nightEndTime) / 60 )+ ":00"}})</div>
      <div class="table">
        <div class="tr">
          <div class="th">计费金额</div>
          <div class="th">免费时长</div>
          <div class="th">重新计费时长</div>
          <div class="th">封顶金额</div>
        </div>
        <div class="tr">
          <div class="td">{{Number(Number(billRule[1].nightBillingPrice)/100).toFixed(2)}}元</div>
          <div class="td">{{billRule[1].nightFreeDuration}}分钟</div>
          <div class="td">{{billRule[1].nightMinBillingDuration}}分钟</div>
          <div class="td">{{Number(Number(billRule[1].nightCeilingAmount)/100).toFixed(2)}}元</div>
        </div>
      </div>
    </div>
    <div class="navBut"
         @click="handleClickNavigate">
      <van-image :src="require('@/assets/aroundParkSpace/daohangbai.png')"></van-image>
      <span>导航</span>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如:组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如:import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      item: this.$route.query,
      parkInfo: {},
      billRule: [
        {
          dayBillingPrice: '-',
          dayCeilingAmount: '-',
          dayFreeDuration: '-',
          dayMinBillingDuration: '-',
          nightBillingPrice: '-',
          nightCeilingAmount: '-',
          nightFreeDuration: '-',
          nightMinBillingDuration: '-'
        },
        {
          dayBillingPrice: '-',
          dayCeilingAmount: '-',
          dayFreeDuration: '-',
          dayMinBillingDuration: '-',
          nightBillingPrice: '-',
          nightCeilingAmount: '-',
          nightFreeDuration: '-',
          nightMinBillingDuration: '-'
        }
      ]
    }
  },
  // 监听属性 类似于data概念
  computed: {
    getDistance () {
      if (Number(this.item.distance) < 1) {
        return [(Number(this.item.distance) * 1000).toFixed(0), '米']
      }
      return [Number(this.item.distance).toFixed(3), '千米']
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getParking()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取停车场信息
    getParking () {
      const info = {
        parkId: this.item.parkId
      }
      this.$carParking.queryParking(info).then(res => {
        this.parkInfo = res.resultEntity[0]
        this.getBillingRule()
      })
    },
    // 获取计费规则
    getBillingRule () {
      const info = {
        billingRuleDefId: this.parkInfo.billingRuleDefId
      }
      this.$carParking.queryBillingRule(info).then(res => {
        // 大车固定为1 小车固定为2
        const data = res.resultEntity.billingRuleDetailList
        const billRule = [{}, {}]
        data.forEach(item => {
          // item
          if (item.vehicleTypeCode === 1) {
            billRule[1] = item
          } else if (item.vehicleTypeCode === 2) {
            billRule[0] = item
          }
        })
        this.billRule = billRule
      })
    },
    // 停车场导航按钮
    handleClickNavigate (item) {
      if (window.android) {
        // 安卓打开第三方地图
        window.android.openMap(this.mylatitude, this.mylongitude, this.myaddress, item.latitude, item.longitude, item.address)
      } else if (window.webkit) {
        let info = {
          mylatitude: this.mylatitude,
          mylongitude: this.mylongitude,
          myaddress: this.myaddress,
          latitude: item.latitude,
          longitude: item.longitude,
          address: item.address
        }
        info = JSON.stringify(info)
        // ios打开第三方地图
        window.webkit.messageHandlers.openMap.postMessage(info)
      }
    },
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .top {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 10px;
    box-sizing: border-box;
    background: #ffffff;
    .top-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      .van-image {
        width: 40px;
      }
    }
    .top-right {
      flex-grow: 8;
      display: flex;
      flex-direction: column;
      width: 80%;
      .prominent {
        color: #19a9fc;
      }
      .parking {
        width: 100%;
        font-size: 17px;
        font-weight: 600;
        line-height: 30px;
        color: #333333;
      }
      .row2 {
        font-size: 15px;
        line-height: 25px;
        color: #909090;
        .row2-1 {
          margin-right: 10px;
        }
      }
      .row4 {
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 20px;
        color: #909090;
        span {
          width: 100%;
        }
        .van-image {
          width: 10px;
          margin-right: 5px;
        }
      }
      .detail {
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 25px;
        color: #19a9fc;
        .van-image {
          width: 6px;
          margin-left: 10px;
        }
      }
    }
  }
  .rule-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 290px);
    margin-top: 15px;
    padding: 10px;
    box-sizing: border-box;
    background: #ffffff;
    overflow: auto;
    .rule-title {
      width: 100%;
      font-size: 15px;
      line-height: 30px;
      font-weight: 600;
      color: #333333;
    }
    .rule-item {
      width: 100%;
      line-height: 23px;
      font-size: 15px;
      color: #555555;
    }
    .table {
      display: table;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 10px;
      .tr {
        display: table-row;
        text-align: center;
        color: #909090;
        .th {
          display: table-cell;
          height: 30px;
          line-height: 30px;
          font-size: 13px;
          border-top: solid 0.5px #ebebeb;
          border-bottom: solid 0.5px #ebebeb;
          border-right: solid 0.5px #ebebeb;
          &:last-child {
            border-right: solid 0px #ebebeb;
          }
        }
        .td {
          display: table-cell;
          width: 20%;
          height: 35px;
          line-height: 35px;
          font-size: 15px;
          border-bottom: solid 0.5px #ebebeb;
        }
      }
    }
  }
  .navBut {
    position: fixed;
    bottom: 20px;
    left: 2.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 40px;
    margin-top: 50px;
    border-radius: 5px;
    background: #19a9fc;
    border-right: 5px;
    font-size: 17px;
    color: #ffffff;
    .van-image {
      width: 17px;
      margin-right: 8px;
    }
    &:active {
      background: #3db8ff;
    }
  }
}
</style>
